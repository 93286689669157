import React, { useContext, useState } from 'react';
import { FaChevronRight, FaTag } from 'react-icons/fa';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { PageBreadcrumbValuesByTypename } from '../../graphql-fragments/pageBreadCrumbValues';

import Layout from '../../components/Layout';
import AssetsPhoto from '../../components/AssetsPhoto';
import { getFirstModuleBreadcrumbValuesByTypename, slugify, sortBy } from '../../utils/utils';
import { DEFAULT_LANG, SITE_DOMAIN } from '../../constants';
import { OpenTermsModalContext } from '../../contexts/OpenTermsModalContext';

import * as styles from './photos.module.scss';
import { withI18n } from '../../utils/hocs';
import { useLocalization } from '../../utils/hooks';
import PageSEO from '../../components/PageSEO';
import LocalizedLink from '../../components/LocalizedLink';

interface LocalizedQueryData {
  allSanityPage: {
    nodes: Array<PageBreadcrumbValuesByTypename>;
  };
  allSanityAssetsPhoto: {
    nodes: Array<{
      label1: string;
      label2?: string;
      image: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
          extension: string;
        };
      };
      category: {
        title: string;
      };
      priority: number | null;
    }>;
  };
}

const INITIAL_PHOTOS = 5;

const PhotosPage = (): React.ReactElement => {
  const openModalIfTermsNotAccepted = useContext(OpenTermsModalContext);

  const [openedCategories, setOpenedCategories] = useState<Record<string, boolean>>({});

  const data = useStaticQuery(
    graphql`
      {
        allSanityPage {
          nodes {
            ...PageBreadcrumbValuesByTypename
          }
        }
        allSanityAssetsPhoto {
          nodes {
            label1 {
              ...LocaleStringFragment
            }
            label2 {
              ...LocaleStringFragment
            }
            image {
              asset {
                url
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 450
                  height: 450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                extension
              }
            }
            category {
              title {
                ...LocaleStringFragment
              }
            }
            priority
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);

  const { nodes: assetsPhotos } = localizedData.allSanityAssetsPhoto;
  const { nodes: pages } = localizedData.allSanityPage;

  const sortedAssetsPhotos = sortBy(assetsPhotos, [
    [photo => (photo.priority !== null ? photo.priority : Number.NEGATIVE_INFINITY), 'desc'],
    [photo => photo.category.title, 'asc'],
    ['_createdAt', 'desc'],
  ]);

  const firstModuleData = getFirstModuleBreadcrumbValuesByTypename(pages, 'SanityAssetsModule');

  const categories: Array<string> = [];
  for (const photoCategory of sortedAssetsPhotos) {
    if (!categories.some(title => title === photoCategory.category.title)) {
      categories.push(photoCategory.category.title);
    }
  }

  return (
    <Layout transparentHeader={false}>
      <PageSEO defaultTitle={t('photos_page.title', 'Photos')}></PageSEO>
      <div className={styles.moduleRoot}>
        <div className={styles.container}>
          <div className={styles.pathContainer}>
            <div className={styles.path}>
              {firstModuleData && (
                <LocalizedLink to={firstModuleData.url} className={'titleParagraph ' + styles.link}>
                  {firstModuleData.name}
                </LocalizedLink>
              )}
              <FaChevronRight className={styles.iconLeft}></FaChevronRight>
            </div>
            <div>
              <span className={'titleParagraph ' + styles.pathTitle}>
                {t('photos_page.title', 'Photos')}
              </span>
            </div>
          </div>

          <div className={styles.sectionTitleContainer}>
            <div className={styles.border}></div>
            <h2>{t('photos_page.title', 'Photos')}</h2>
            <div className={styles.border}></div>
          </div>

          {categories.length > 0 && (
            <div className={styles.anchorContainer}>
              <FaTag className={styles.tagIcon}></FaTag>
              <div className={styles.anchorLinksContainer}>
                {categories.map((category, index) => {
                  return (
                    <AnchorLink
                      key={index}
                      href={'#' + slugify(category)}
                      className={styles.anchorLink}
                      offset="180"
                    >
                      {category}
                    </AnchorLink>
                  );
                })}
              </div>
            </div>
          )}

          {categories.length > 0 &&
            categories.map((category, index) => {
              const photosByCategory = sortedAssetsPhotos.filter(
                assetsPhoto => assetsPhoto.category.title === category,
              );
              return (
                <div
                  key={index}
                  id={slugify(category)}
                  className={
                    styles.photosByCategory +
                    ' ' +
                    (openedCategories[category] ? styles.categoryOpen : '')
                  }
                >
                  <h3>{category}</h3>
                  <div className={styles.photosContainer}>
                    {photosByCategory
                      .slice(
                        0,
                        openedCategories[category] ? photosByCategory.length : INITIAL_PHOTOS,
                      )
                      .map((photo, index) => {
                        return (
                          <AssetsPhoto
                            label1={photo.label1}
                            label2={photo.label2}
                            image={photo.image}
                            key={index}
                            className={styles.photo}
                            onDownloadClick={openModalIfTermsNotAccepted}
                          ></AssetsPhoto>
                        );
                      })}
                  </div>
                  {photosByCategory.length > INITIAL_PHOTOS && !openedCategories[category] && (
                    <div className={styles.loadMoreButton}>
                      <button
                        className={styles.button}
                        onClick={() => {
                          setOpenedCategories({ ...openedCategories, [category]: true });
                        }}
                      >
                        {t('photos_page.view_more_link', 'View More')}
                        <FaChevronRight className={styles.iconRight}></FaChevronRight>
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default withI18n(PhotosPage);
