// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var categoryContainer = "AssetsPhoto-module--categoryContainer--oqquy";
export var categoryIcon = "AssetsPhoto-module--categoryIcon--Tm48d";
export var categoryTitle = "AssetsPhoto-module--categoryTitle--2VeZ2";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var downloadIcon = "AssetsPhoto-module--downloadIcon--OOmBe";
export var downloadIconLink = "AssetsPhoto-module--downloadIconLink--9y81C";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "AssetsPhoto-module--image--yYphw";
export var imageContainer = "AssetsPhoto-module--imageContainer--fCVU5";
export var imageContainerWrapper = "AssetsPhoto-module--imageContainerWrapper--qWRiG";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var lightboxImage = "AssetsPhoto-module--lightboxImage--sIHkQ";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var photoCard = "AssetsPhoto-module--photoCard--BHc2o";
export var photoLabels = "AssetsPhoto-module--photoLabels--gtFQ4";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";