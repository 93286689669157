// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var anchorContainer = "photos-module--anchorContainer--o5fnB";
export var anchorLink = "photos-module--anchorLink--kVC+o";
export var anchorLinksContainer = "photos-module--anchorLinksContainer--8TWZD";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var border = "photos-module--border--nZ4DQ";
export var button = "photos-module--button--iIqa2";
export var categoryOpen = "photos-module--categoryOpen--7JryK";
export var container = "photos-module--container--1mUrQ";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var downloadIcon = "photos-module--downloadIcon--hQoI-";
export var downloadIconLink = "photos-module--downloadIconLink--OExK5";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var iconLeft = "photos-module--iconLeft--UaLYA";
export var iconRight = "photos-module--iconRight--4xynX";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "photos-module--link--Qf2kZ";
export var loadMoreButton = "photos-module--loadMoreButton--g6ong";
export var moduleRoot = "photos-module--moduleRoot--RceSJ";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var path = "photos-module--path--tNiJG";
export var pathContainer = "photos-module--pathContainer--d6XHY";
export var pathTitle = "photos-module--pathTitle--LFoF8";
export var photo = "photos-module--photo--MJaZy";
export var photoLabels = "photos-module--photoLabels--BpPha";
export var photosByCategory = "photos-module--photosByCategory--99rig";
export var photosContainer = "photos-module--photosContainer--bPma9";
export var sectionTitleContainer = "photos-module--sectionTitleContainer--51Dch";
export var success = "#1ebc7b";
export var tagIcon = "photos-module--tagIcon--W8+Ev";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";