import React, { useState } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FaDownload, FaTag } from 'react-icons/fa';

import * as styles from './AssetsPhoto.module.scss';
import { Portal } from '@material-ui/core';
import FsLightbox from 'fslightbox-react';

interface AssetsPhotoProps {
  label1: string;
  label2?: string;
  category?: {
    title: string;
  };
  image: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
      extension: string;
    };
  };
  className?: string;
  onDownloadClick?: ((url: string) => void) | null;
}

const AssetsPhoto = ({
  label1,
  label2,
  image,
  category,
  className,
  onDownloadClick,
}: AssetsPhotoProps): React.ReactElement => {
  const [lightboxToggler, setLightboxToggler] = useState(false);

  const photoDownloadUrl =
    image.asset.url + '?dl=' + encodeURIComponent(label1) + '.' + image.asset.extension;

  return (
    <div className={styles.photoCard + ' ' + className}>
      <div className={styles.imageContainerWrapper}>
        <Portal>
          <FsLightbox
            toggler={lightboxToggler}
            sources={[
              // @ts-ignore
              <img
                src={image.asset.url + '?w=1000&h=600&fit=max'}
                className={styles.lightboxImage}
                key="photo"
              ></img>,
            ]}
          ></FsLightbox>
        </Portal>
        <GatsbyImage
          alt={label1 + (label2 ? ' ' + label2 : '')}
          imgClassName={styles.image}
          className={styles.imageContainer}
          image={image.asset.gatsbyImageData}
          onClick={() => {
            setLightboxToggler(!lightboxToggler);
          }}
        ></GatsbyImage>

        <a
          className={styles.downloadIconLink}
          href={!onDownloadClick ? photoDownloadUrl : undefined}
          onClick={onDownloadClick ? () => onDownloadClick(photoDownloadUrl) : undefined}
        >
          <FaDownload className={styles.downloadIcon}></FaDownload>
        </a>

        {category && (
          <div className={styles.categoryContainer}>
            <FaTag className={styles.categoryIcon}></FaTag>
            <span className={styles.categoryTitle + ' titleParagraph'}>{category.title}</span>
          </div>
        )}
      </div>
      <div className={styles.photoLabels}>
        {/* {category && <span>{category.title}</span>} */}
        <span>{label1}</span>
        {label2 && <span>{label2}</span>}
      </div>
    </div>
  );
};

export default AssetsPhoto;
